<template>
  <div>
    <v-data-table
      :fixed-header="false"
      :height="undefined"
      v-bind="dataTableAttrs"
      :headers="headers"
      :items="list"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page.sync="itemsPerPage"
      v-model="selectedItems"
      @update:items-per-page="handleItemsPerPageUpdated"
    >
      <template v-slot:top>
        <rs-table-title-bar
          v-if="$refs.headSearch"
          title=""
          icon=""
          @clear-filters="$refs.headSearch.reset"
          @reload="loadList"
          :edit-enabled="selectedItems.length === 1"
          hide-edit
          :search.sync="search.query"
        >
          <template slot="buttons:append">
            <!-- <v-btn
              class="ms-2"
              color="pink"
              outlined
              small
              @click="showAutoAssessmentForm = true"
              :disabled="selectedItems.length !== 1"
            >
              Tahakkuk Fişi Yazdır
            </v-btn> -->

            <v-btn
              class="ms-2"
              color="pink"
              outlined
              small
              @click="showAutoAssessmentForm = true"
              :disabled="selectedItems.length === 0"
            >
              Mobil Borç Bildirimi
            </v-btn>
          </template>
        </rs-table-title-bar>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:body.prepend="{ headers }">
        <rs-table-head-search
          :headers="headers"
          :search.sync="search"
          ref="headSearch"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.debt_number="{ item, value }">
        <a
          href="#"
          v-if="$refs.debtDetail"
          @click.prevent="$refs.debtDetail.show(item.id)"
        >
          {{ value }}
        </a>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.last_payment_on="{ value }">
        <rs-table-cell-date :value="value" />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.issues_on="{ value }">
        <rs-table-cell-date :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.amount="{ value }">
        <rs-table-cell-number price :value="value" />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.deferment_amount="{ value }">
        <rs-table-cell-number price :value="value" />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.collected_deferment="{ value }">
        <rs-table-cell-number price :value="value" />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.deferment_balance="{ value }">
        <rs-table-cell-balance colored :value="value" />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.total_assessment="{ value }">
        <rs-table-cell-number price :value="value" />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.remaining_amount="{ value }">
        <rs-table-cell-balance colored :value="value" />
      </template>
    </v-data-table>

    <DebtDetail ref="debtDetail" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { hasDataTable } from "@/view/mixins";
import { DebtDetail } from "@/view/pages/income/forms";

export default {
  name: "DeferInfo",
  mixins: [hasDataTable],
  components: {
    DebtDetail,
  },
  props: {
    listType: {
      type: String,
      required: false,
      default: "all",
    },
    houseId: {
      type: Number,
      required: false,
    },
    residentId: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["incomeTypeList"]),
  },
  watch: {
    houseId() {
      this.loadList();
    },
    residentId() {
      this.loadList();
    },
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
  data() {
    return {
      options: {
        sortBy: ["issues_on"],
      },
      headers: [
        {
          text: "Sistem No",
          value: "debt_number",
          searchable: "text",
        },
        {
          text: "Tahakkuk Tarihi",
          value: "issues_on",
          searchable: "date",
        },
        {
          text: "Fiş Türü",
          value: "income_type",
          searchable: "select",
          options: () => this.incomeTypeList,
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: "text",
        },
        {
          text: "Son Ödeme Tarihi",
          value: "last_payment_on",
          searchable: "date",
          width: "120px",
        },
        {
          text: "Tahakkuk Eden",
          value: "amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Gecikme Tahakkuk",
          value: "deferment_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Gecikme Ödenen",
          value: "collected_deferment",
          searchable: "number",
          align: "end",
        },
        {
          text: "Gecikme Bakiye",
          value: "deferment_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Toplam Tahakkuk",
          value: "total_assessment",
          searchable: "number",
          align: "end",
        },
        {
          text: "Borçlu/Ödeyen",
          value: "resident",
          searchable: "text",
        },
        {
          text: "Ana Borç Kalan Tutar",
          value: "remaining_amount",
          searchable: "number",
          align: "end",
          width: "120px",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading || (!this.houseId && !this.residentId)) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (this.houseId) {
        params.house_id = this.houseId;
      }

      if (this.residentId) {
        params.resident_id = this.residentId;
      }

      if (this.listType === "owner") {
        params.house_user_type_id = 1;
      } else if (this.listType === "renter") {
        params.house_user_type_id = 2;
      }

      this.$api
        .query("income/deferments", { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
