<template>
  <v-expansion-panels class="elevation-5">
    <v-expansion-panel>
      <v-expansion-panel-header color="blue lighten-5">
        <strong>Borç Türüne Göre Özet</strong>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-simple-table class="my-10" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Borç Türü</th>
                <th>Toplam Tahakkuk</th>
                <th>Toplam Gecikme</th>
                <th>Toplam Bakiye</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in debtTypeList" :key="item.name">
                <td>
                  <strong>{{ item.name }}</strong>
                </td>
                <td class="text-end">
                  <rs-table-cell-number price :value="item.total_assessment" />
                </td>
                <td class="text-end">
                  <rs-table-cell-number price :value="item.total_defer" />
                </td>
                <td class="text-end">
                  <rs-table-cell-balance colored :value="item.total_balance" />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  computed: {
    debtTypeList() {
      const list = [];
      const indexes = [];

      for (const index in this.list) {
        const item = this.list[index];

        if (!item.income_type_id) {
          continue;
        }

        let listIndex = indexes.indexOf(item.income_type_id);

        if (listIndex === -1) {
          listIndex =
            list.push({
              name: item.income_type,
              total_assessment: 0,
              total_defer: 0,
              total_balance: 0,
            }) - 1;
          indexes.push(item.income_type_id);
        }

        list[listIndex].total_assessment += parseFloat(item.assessment);
        list[listIndex].total_defer += parseFloat(item.defer_assessment);
        list[listIndex].total_balance += parseFloat(item.total_balance);
      }

      return list;
    },
  },
};
</script>
