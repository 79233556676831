<template>
  <div>
    <v-data-table
      v-if="houseId || residentId"
      v-bind="dataTableAttrs"
      :headers="headers"
      :items="list"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page.sync="itemsPerPage"
      v-model="selectedItems"
      @update:items-per-page="handleItemsPerPageUpdated"
      :item-class="(item) => item.cssClass"
    >
      <template v-slot:top>
        <rs-table-title-bar
          v-if="$refs.headSearch"
          title=""
          icon=""
          @clear-filters="$refs.headSearch.reset"
          @reload="loadList"
          :search.sync="search.query"
          hide-edit
          v-bind="titleBarAttrs"
        >
          <template slot="buttons:append">
            <v-btn
              class="ms-2"
              color="pink"
              outlined
              small
              @click="handleDeleteClick"
              accesskey="2"
              v-if="
                can('edit-house-income') &&
                selectedItems.length === 1 &&
                selectedItems[0].type === 'collecting' &&
                selectedItems[0].enforcement_transaction_id == null
              "
            >
              Tahsilat Sil
            </v-btn>

            <v-btn
              class="ms-2"
              color="pink"
              outlined
              small
              @click="handleDeleteClick"
              v-if="
                can('edit-house-income') &&
                selectedItems.length === 1 &&
                selectedItems[0].type === 'debt'
              "
            >
              Borç Fişi Sil
            </v-btn>

            <v-btn
              class="ms-2"
              color="pink"
              outlined
              small
              @click="handleDeleteClick"
              v-if="
                can('edit-house-income') &&
                selectedItems.length === 1 &&
                (selectedItems[0].type === 'payback' ||
                  selectedItems[0].type === 'loss')
              "
            >
              Diğer Gider Sil
            </v-btn>

            <v-btn
              class="ms-2"
              color="pink"
              outlined
              small
              @click="$refs.debtResidentForm.show()"
              v-if="
                can('edit-house-income') &&
                debtShareEnabled &&
                !hideDebtResidentForm
              "
            >
              Borçlu/Ödeyen Değiştir
            </v-btn>

            <!-- <v-btn
              class="ms-2"
              color="pink"
              outlined
              small
              @click="$refs.collectingTypeForm.show()"
              v-if="
                selectedItems.length === 1 &&
                selectedItems[0].type === 'collecting'
              "
            >
              Tahsilat Türü Değiştir
            </v-btn> -->

            <v-btn
              class="ms-2"
              color="pink"
              outlined
              small
              @click="$refs.collectingReceiptNoForm.show()"
              v-if="
                can('edit-house-income') &&
                selectedItems.length === 1 &&
                selectedItems[0].type === 'collecting'
              "
            >
              Makbuz Seri No Değiştir
            </v-btn>

            <v-btn
              class="ms-2"
              color="pink"
              outlined
              small
              @click="$refs.collectingReceiptForm.show()"
              v-if="
                can('see-house-income') &&
                can('print') &&
                selectedItems.length === 1 &&
                selectedItems[0].type === 'collecting'
              "
            >
              Tahsilat Fişi Yazdır
            </v-btn>
          </template>
        </rs-table-title-bar>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:body.prepend="{ headers }">
        <rs-table-head-search
          :headers="headers"
          :search.sync="search"
          ref="headSearch"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:body.append="{ headers }">
        <rs-table-foot-totals :headers="headers" :totals="footTotals" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.balance_no="{ item, value }">
        <a href="#" @click.prevent="handleEditClick(item)">
          <rs-table-cell-string :value="value" limit="20" />
        </a>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.door_number="{ item, value }">
        <router-link
          :to="{
            name: 'incomes.houses.show',
            params: { id: item.house_id },
          }"
        >
          {{ value }}
        </router-link>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.resident_balance="{ value }">
        <rs-table-cell-balance colored :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.house_balance="{ value }">
        <rs-table-cell-balance colored :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.house_user_balance="{ value }">
        <rs-table-cell-balance colored :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.payed_on="{ value }">
        <rs-table-cell-date :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.last_payment_on="{ value }">
        <rs-table-cell-date :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.action_on="{ value }">
        <rs-table-cell-date :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.debt_amount="{ value }">
        <rs-table-cell-number price :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.deferment_amount="{ value, item }">
        <rs-table-cell-number v-if="!item.collecting_id" price :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.debt_total_amount="{ value, item }">
        <rs-table-cell-number v-if="!item.collecting_id" price :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.collecting_amount="{ value }">
        <rs-table-cell-number price :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.debt_amount_left="{ value }">
        <rs-table-cell-number price :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.collecting_amount_left="{ value }">
        <rs-table-cell-number price :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.is_transferred="{ value }">
        <rs-table-cell-boolean :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.is_deferment="{ value }">
        <rs-table-cell-boolean :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.description="{ value }">
        <rs-table-cell-string limit="20" :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.block_id="{ item }">
        {{ item.block }}
      </template>

      <!-- eslint-disable-next-line -->
      <!-- <template v-slot:item.bill_type="{ item }">
        <template v-if="item.debt_id">{{ item.income_type_name }}</template>
        <template v-else-if="item.collecting_id">
          {{ item.collecting_type_name }}
        </template>
      </template> -->

      <!-- eslint-disable-next-line -->
      <template v-slot:item.bill_no="{ item }">
        <template v-if="item.debt_id">
          <a href="#" @click.prevent="showDebtDetail(item)">
            <template v-if="item.debt_number">
              {{ item.debt_number }}
            </template>
            <template v-else>
              <em>Girilmemiş</em>
            </template>
          </a>
        </template>
        <template v-else-if="item.collecting_id">
          <a href="#" @click.prevent="showCollectingDetail(item)">
            <template v-if="item.collecting_number">
              {{ item.collecting_number }}
            </template>
            <template v-else>
              <em>Girilmemiş</em>
            </template>
          </a>
        </template>
        <template v-else-if="item.other_expense_id">
          <template>
            <em>Girilmemiş</em>
          </template>
        </template>
      </template>
    </v-data-table>

    <CollectingDetail ref="collectingDetailForm" @saved="loadList()" />

    <CollectingReceiptForm
      :house="house"
      ref="collectingReceiptForm"
      :selected="selectedItems"
    />

    <CollectingReceiptNoForm
      :house="house"
      ref="collectingReceiptNoForm"
      :selected="selectedItems"
      @saved="loadList()"
    />
    <CollectingTypeForm
      :house="house"
      ref="collectingTypeForm"
      :selected="selectedItems"
      @saved="loadList()"
    />

    <DebtResidentForm
      :house="house"
      ref="debtResidentForm"
      :selected="selectedItems"
      @saved="loadList()"
    />

    <DebtDetail ref="debtDetailForm" />

    <DebtForm ref="debtForm" />
    <OtherExpenseForm ref="otherExpenseForm" />

    <rs-confirm
      ref="confirmDelete"
      :loading="isLoading"
      @confirmed="handleDeleteSubmitClick"
      @cancelled="$refs.confirmDelete.hide()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { hasExport, hasDataTable, hasPermissions } from "@/view/mixins";

import {
  CollectingDetail,
  CollectingReceiptForm,
  CollectingReceiptNoForm,
  CollectingTypeForm,
  DebtDetail,
  DebtForm,
  DebtResidentForm,
} from "@/view/pages/income/forms";
import OtherExpenseForm from "@/view/pages/expense/forms/OtherExpenseForm";

export default {
  mixins: [hasExport, hasDataTable, hasPermissions],
  components: {
    CollectingDetail,
    CollectingReceiptForm,
    CollectingReceiptNoForm,
    CollectingTypeForm,
    DebtForm,
    DebtDetail,
    DebtResidentForm,
    OtherExpenseForm,
  },
  props: {
    houseId: {
      type: Number,
      required: false,
      default: null,
    },
    house: {
      type: Object,
      required: false,
      default: null,
    },
    residentId: {
      type: Number,
      required: false,
      default: null,
    },
    resident: {
      type: Object,
      required: false,
      default: null,
    },
    listType: {
      type: String,
      required: false,
      default: "all",
    },
    hideDebtResidentForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["blocks", "cluster", "clusterId"]),
    residentList() {
      if (!this.house || !this.house.house_users) {
        return false;
      }

      const list = [];

      for (const index in this.house.house_users) {
        list.push({
          id: this.house.house_users[index].resident.id,
          name: this.house.house_users[index].resident.name,
        });
      }

      return list;
    },
    debtShareEnabled() {
      if (this.selectedItems.length === 0) {
        return false;
      }

      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];

        if (item.has_enforcement === 1) {
          return false;
        }

        if (item.enforcement_transaction_id) {
          return false;
        }

        if (item.debt_id) {
          if (item.total_payed > 0) {
            return false;
          }

          if (item.income_type_id === 15) {
            return false;
          }

          return true;
        } else if (
          item.collecting_id &&
          item.collecting_amount !== item.collecting_amount_left
        ) {
          return false;
        }
      }

      return true;
    },
  },
  watch: {
    houseId() {
      this.loadList();
    },
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  data() {
    return {
      options: {
        sortBy: ["order_num"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "Sistem No",
          value: "balance_no",
          searchable: "text",
        },
        {
          text: "Tarih",
          value: "action_on",
          searchable: "date",
        },
        {
          text: "Blok",
          value: "block",
          searchable: "select",
          options: () => this.blocks,
        },
        {
          text: "BB",
          value: "door_number",
          searchable: "text",
        },
        {
          text: "Fiş Türü",
          value: "bill_type",
          searchable: "text",
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: "text",
        },
        {
          text: "Tahakkuk Eden",
          value: "debt_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Gecikme Tahakkuk",
          value: "deferment_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Toplam Tahakkuk",
          value: "debt_total_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Ödenen Tutar",
          value: "collecting_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Kişi Bakiye",
          value: "resident_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Borçlu / Ödeyen",
          value: "resident",
          searchable: "select",
          options: () => this.residentList,
          itemValue: "name",
          width: "120px",
        },
        {
          text: "Banka / Kasa",
          value: "bank_safe",
          searchable: "bankSafe",
        },
        {
          text: "BB Bakiye",
          value: "house_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Borç Kalan Tutar",
          value: "debt_amount_left",
          searchable: "number",
          align: "end",
          width: "120px",
        },
        {
          text: "Son Ödeme Tarihi",
          value: "last_payment_on",
          searchable: "date",
          width: "120px",
        },
        {
          text: "Ödenen Tarih",
          value: "payed_on",
          searchable: "date",
        },
        {
          text: "Tahsilat Makbuz No",
          value: "collecting_number",
          searchable: "text",
          width: "120px",
        },
        {
          text: "Tahsilat Kalan",
          value: "collecting_amount_left",
          searchable: "number",
          align: "end",
        },
        {
          text: "BB / Kişi Bakiye",
          value: "house_user_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Gecikme",
          value: "is_deferment",
          searchable: "yesNo",
          align: "center",
        },
        {
          text: "Devir",
          value: "is_transferred",
          searchable: "yesNo",
          align: "center",
        },
      ],
      titleBarAttrs: {
        exportUrl: "balance-activities",
        exportParams: this.getParams,
      },
    };
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (this.listType === "owner") {
        params.house_user_type_id = 1;
      } else if (this.listType === "renter") {
        params.house_user_type_id = 2;
      }

      if (this.houseId) {
        params.house_id = this.houseId;
      } else if (this.residentId) {
        params.resident_id = this.residentId;
      }

      params.cluster_id = this.clusterId;

      return params;
    },
    loadList() {
      if (
        this.isLoading ||
        (!this.houseId && !this.residentId) ||
        !this.clusterId
      ) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.selectedItems = [];
      this.footTotals = [];

      const params = this.getParams();

      this.$api
        .query("balance-activities", { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              if (item.collecting_id) {
                item.type = "collecting";
                if (item.bank_account_id === this.cluster.pos_account_id) {
                  // it's a credit card payment
                  item.cssClass = "purple lighten-5";
                } else {
                  item.cssClass = "teal lighten-5";
                }
              } else if (item.other_expense_id) {
                if (item.type === "payback") {
                  item.cssClass = "amber lighten-4";
                } else if (item.type === "loss") {
                  item.cssClass = "red lighten-4";
                }
              } else if (item.debt_id) {
                item.type = "debt";
                item.cssClass = "amber lighten-5";
              } else if (item.enforcement_transaction_id) {
                item.type = "collecting";
                item.cssClass = "teal lighten-4";
              }

              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;

            this.$emit("update:balance");
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleDeleteClick() {
      if (this.selectedItems.length !== 1) {
        return;
      }

      this.$refs.confirmDelete.show("Silmek istediğinizden emin misiniz?");
    },
    handleDeleteSubmitClick() {
      this.$refs.confirmDelete.hide();

      if (this.selectedItems.length === 1) {
        if (this.selectedItems[0].type === "debt") {
          this.deleteDebt(this.selectedItems[0].debt_id);
        } else if (this.selectedItems[0].type === "collecting") {
          this.deleteCollecting(this.selectedItems[0].collecting_id);
        } else if (
          ["payback", "loss"].indexOf(this.selectedItems[0].type) !== -1
        ) {
          this.deleteOtherExpense(this.selectedItems[0].other_expense_id);
        }
      }
    },
    deleteCollecting(collectingId) {
      if (this.isLoading || !collectingId) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .delete(`income/collectings/${collectingId}`)
        .then(() => {
          this.$toast.success("Tahsilat silindi.");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.loadList();
          this.$emit("update:balance");
        });
    },
    deleteDebt(debtId) {
      if (this.isLoading || !debtId) {
        return Promise.reject();
      }

      this.isLoading = true;

      return this.$api
        .delete(`income/debts/${debtId}`)
        .then(() => {
          this.$toast.success("Borç silindi.");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.loadList();
          this.$emit("update:balance");
        });
    },
    deleteOtherExpense(otherExpenseId) {
      if (this.isLoading || !otherExpenseId) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .delete(`expense/other-expenses/${otherExpenseId}`)
        .then(() => {
          this.$toast.success("Diğer gider silindi.");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.loadList();
          this.$emit("update:balance");
        });
    },
    showCollectingDetail(item) {
      this.$refs.collectingDetailForm.show(item.collecting_id);
    },
    showDebtDetail(item) {
      this.$refs.debtDetailForm.show(item.debt_id);
    },
    handleEditClick(item) {
      if (!item.id) {
        item = this.selectedItems[0];
      }

      if (item.enforcement_transaction_id) {
        this.$router.push({
          name: "enforcements.show",
          params: { id: item.enforcement_id },
        });
      } else if (item.type === "collecting") {
        this.$refs.collectingDetailForm.show(item.collecting_id);
      } else if (item.type === "debt") {
        this.$refs.debtForm.show(item.debt_id, true);
      } else if (item.type === "invoice_payment" || item.type === "invoice") {
        this.$router.push({
          name: "expenses.invoices.edit",
          params: { id: item.invoice_id },
        });
      } else if (item.type === "loss") {
        this.$refs.otherExpenseForm.show(item.other_expense_id, true);
      } else if (item.type === "payback") {
        this.$refs.otherExpenseForm.show(item.other_expense_id, true);
      } else if (item.type === "other_income_payment") {
        this.isLoading = true;

        this.$api
          .get(`income/payments/${item.other_income_payment_id}`)
          .then((response) => {
            this.$router.push({
              name: "incomes.other.edit",
              params: { id: response.data.data.other_income_id },
            });
          })
          .finally(() => (this.isLoading = false));
        // this.$refs.otherIncomePaymentForm.show(
        //   item.other_income_payment_id,
        //   null,
        //   true
        // );
      } else if (item.type === "openning") {
        this.$refs.firstBalanceForm.show(item.id, {
          safe: this.safe,
          bankAccount: this.bankAccount,
        });
      }
    },
  },
};
</script>
